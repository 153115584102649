import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
} from '@mui/material';

const AssignCardModal = ({ open, handleClose, handleSubmit }) => {
  const [step, setStep] = useState(1);
  const [parentCode, setParentCode] = useState('');
  const [businessCode, setBusinessCode] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const [placement, setPlacement] = useState('Person'); // default option

  const handleYes = () => {
    // Advance to step 2
    setStep(2);
  };

  const handleNext = () => {
    // Advance from step 2 to step 3
    setStep(3);
  };

  const onSubmit = () => {
    // Gather all the data from the modal and pass it upward.
    const submissionData = { parentCode, businessCode, assignedTo, placement };
    console.log("Submitted Codes:", submissionData);
    setStep(4); // Show "Submitting..." screen
    handleSubmit(submissionData);
    // Do not reset the state here; the parent will close the modal on success.
  };

  const onCancel = () => {
    // Reset modal state and close
    setStep(1);
    setParentCode('');
    setBusinessCode('');
    setAssignedTo('');
    setPlacement('Person');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      {step === 1 && (
        <>
          <DialogTitle>Assign Card</DialogTitle>
          <DialogContent>
            <Typography>
              Card is not yet assigned. Would you like to assign this card today?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              No
            </Button>
            <Button onClick={handleYes} color="primary">
              Yes
            </Button>
          </DialogActions>
        </>
      )}
      {step === 2 && (
        <>
          <DialogTitle>Assign Card - Step 2</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Enter Parent and Business/Location Codes:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                label="Parent Company Code"
                value={parentCode}
                onChange={(e) => setParentCode(e.target.value)}
              />
              <TextField
                label="Business/Location Code"
                value={businessCode}
                onChange={(e) => setBusinessCode(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleNext} color="primary">
              Next
            </Button>
          </DialogActions>
        </>
      )}
      {step === 3 && (
        <>
          <DialogTitle>Assign Card - Step 3</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Enter Additional Card Details:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                label="Assigned To"
                value={assignedTo}
                onChange={(e) => setAssignedTo(e.target.value)}
              />
              <TextField
                select
                label="Placement"
                value={placement}
                onChange={(e) => setPlacement(e.target.value)}
              >
                <MenuItem value="Person">Person</MenuItem>
                <MenuItem value="Stall/DT">Stall/DT</MenuItem>
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={onSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </>
      )}
      {step === 4 && (
        <>
          <DialogTitle>Submitting</DialogTitle>
          <DialogContent>
            <Typography>
              Submitting your assignment. Please wait...
            </Typography>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default AssignCardModal;
