// src/components/HomePage.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  serverTimestamp,
  collection,
  addDoc,
  runTransaction,
  collectionGroup,
  query,
  where,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import NoTagHomePage from './NoTagHomePage';
import AssignCardModal from './AssignCardModal';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E88E5', // Blue
    },
    secondary: {
      main: '#D81B60', // Pink
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

const HomePage = () => {
  const [clientData, setClientData] = useState(null);
  const [platformData, setPlatformData] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showNoTagHomePage, setShowNoTagHomePage] = useState(false);
  const [errorMsgResponse, setErrorMsgResponse] = useState(null);
  // New state to control our assign card modal
  const [showAssignCardModal, setShowAssignCardModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const queryParams = new URLSearchParams(location.search);
  const tagId = queryParams.get('tagId');

  const hitLogged = useRef(false);

  const isLocalStorageAvailable = () => {
    try {
      const testKey = '__storage_test__';
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!tagId) {
      setShowNoTagHomePage(true);
      setLoading(false);
      return;
    }
    logHit();
  }, [tagId]);

  const logHit = async () => {
    if (hitLogged.current) {
      return;
    }
    hitLogged.current = true;

    try {
      // Query all nfcTags matching the provided tagId
      const nfcTagsQuery = query(
        collectionGroup(db, 'nfcTags'),
        where('tagId', '==', tagId)
      );
      const querySnapshot = await getDocs(nfcTagsQuery);

      // If the card is not found in Firestore, log and exit early.
      if (querySnapshot.empty) {
        console.log('Card not found in firestore');
        setLoading(false);
        return;
      }

      // Card is found – continue processing.
      const nfcTagDoc = querySnapshot.docs[0];
      const clientDocRef = nfcTagDoc.ref.parent.parent;
      const clientIdValue = clientDocRef.id;
      setClientId(clientIdValue);

      const clientDocSnap = await getDoc(clientDocRef);
      if (!clientDocSnap.exists()) {
        console.log('Client data not found in firestore');
        // Trigger the modal when client data is missing.
        setShowAssignCardModal(true);
        setLoading(false);
        return;
      }
      const clientDataFetched = clientDocSnap.data();
      setClientData(clientDataFetched);

      // Trigger the modal only when BOTH conditions are met:
      // 1. assignedTo is falsy
      // 2. displayName is exactly "1aCardHolder"
      if (!clientDataFetched.assignedTo && clientDataFetched.displayName === '1aCardHolder') {
        console.log('unassigned and 1aCardHolder');
        setShowAssignCardModal(true);
      }

      // Fetch the platform data
      const platformDocRef = doc(
        db,
        'platforms',
        clientDataFetched.reviewPlatform.toLowerCase()
      );
      const platformDocSnap = await getDoc(platformDocRef);
      if (!platformDocSnap.exists()) {
        throw new Error('Platform data not found.');
      }
      setPlatformData(platformDocSnap.data());

      // Prepare to log the hit if it hasn't been logged this month.
      const storageAvailable = isLocalStorageAvailable();
      const now = new Date();
      const monthKey = `${now.getFullYear()}-${now.getMonth() + 1}`;
      const hitKey = `hit-${tagId}-${monthKey}`;

      let hasHitThisMonth = false;

      if (storageAvailable) {
        hasHitThisMonth = localStorage.getItem(hitKey) === 'true';
      } else {
        hasHitThisMonth = true;
        console.warn('localStorage is not available. Skipping hit increment to prevent duplicates.');
      }

      if (!hasHitThisMonth) {
        await runTransaction(db, async (transaction) => {
          transaction.update(nfcTagDoc.ref, {
            totalHits: increment(1),
            lastHit: serverTimestamp(),
          });

          const tagUsageCollectionRef = collection(db, 'tagUsage');
          const tagUsageDocRef = doc(tagUsageCollectionRef);
          transaction.set(tagUsageDocRef, {
            clientId: clientIdValue,
            nfcTagId: tagId,
            action: 'hit',
            timestamp: serverTimestamp(),
          });
        });

        if (storageAvailable) {
          localStorage.setItem(hitKey, 'true');
        }
      } else {
        console.log('Skipping increment due to previous hit this month or no localStorage.');
      }
    } catch (error) {
      console.error('Error logging hit:', error);
      setErrorMsg('An error occurred while logging your visit.');
      hitLogged.current = false;
    } finally {
      setLoading(false);
    }
  };

  const generateTokenId = () => {
    const timestamp = Date.now().toString(36);
    const randomNum = Math.random().toString(36).substring(2, 8);
    return `${timestamp}-${randomNum}`;
  };

  const generateAndStoreToken = async (clientId) => {
    const newTokenId = generateTokenId();
    const tokenDocRef = doc(collection(db, 'tokens'), newTokenId);

    const tokenData = {
      tokenId: newTokenId,
      clientId: clientId,
      used: false,
      timestamp: new Date(),
    };

    await setDoc(tokenDocRef, tokenData);

    try {
      localStorage.setItem('accessToken', newTokenId);
    } catch (error) {
      console.warn('localStorage is not available:', error);
    }
  };

  const handleToken = async (clientId) => {
    if (!clientId) {
      throw new Error('Client ID is missing.');
    }

    const storageAvailable = isLocalStorageAvailable();
    if (!storageAvailable) {
      console.warn('No localStorage available. Proceeding without token storage.');
      return;
    }

    let existingToken;
    try {
      existingToken = localStorage.getItem('accessToken');
    } catch (error) {
      console.warn('Error accessing localStorage:', error);
      existingToken = null;
    }

    if (existingToken) {
      const existingTokenDocRef = doc(db, 'tokens', existingToken);
      const existingTokenSnap = await getDoc(existingTokenDocRef);

      if (!existingTokenSnap.exists()) {
        await generateAndStoreToken(clientId);
      }
    } else {
      await generateAndStoreToken(clientId);
    }
  };

  const handleResponse = async (responseType, responseDetail) => {
    if (!clientData || !tagId || !clientId) return;

    try {
      const storageAvailable = isLocalStorageAvailable();
      const now = new Date();
      const monthKey = `${now.getFullYear()}-${now.getMonth() + 1}`;
      const yesKey = `yes-${tagId}-${monthKey}`;
      const noKey = `no-${tagId}-${monthKey}`;

      const nfcTagDocRef = doc(db, 'clients', clientId, 'nfcTags', tagId);

      // For the root-level data about the tag (if needed)
      const rootNfcTagDocRef = doc(db, 'nfcTags', tagId);
      const rootNfcTagSnap = await getDoc(rootNfcTagDocRef);
      let assignedToField = null;
      let placementField = null;
      if (rootNfcTagSnap.exists()) {
        const rootNfcData = rootNfcTagSnap.data();
        assignedToField = rootNfcData.assignedTo || null;
        placementField = rootNfcData.placement || null;
      }

      const displayNameField = clientData.displayName || null;
      const reviewUrl = `${platformData.baseUrl}${clientData.platformId}`;
      const isSonic = clientData.reviewPlatform === 'Sonic';

      if (responseType === 'yes') {
        let hasSaidYesThisMonth = false;
        if (storageAvailable) {
          hasSaidYesThisMonth = localStorage.getItem(yesKey) === 'true';
        } else {
          hasSaidYesThisMonth = true;
        }

        if (!hasSaidYesThisMonth) {
          await updateDoc(nfcTagDocRef, { totalYes: increment(1) });
          await addDoc(collection(db, 'clients', clientId, 'nfcTags', tagId, 'responses'), {
            responseType: 'yes',
            responseDetail,
            timestamp: serverTimestamp(),
            clientId,
            assignedTo: assignedToField,
            placement: placementField,
            displayName: displayNameField,
          });

          if (storageAvailable) {
            localStorage.setItem(yesKey, 'true');
          }

          await addDoc(collection(db, 'tagUsage'), {
            clientId,
            nfcTagId: tagId,
            action: 'yes',
            timestamp: serverTimestamp(),
          });
        }

        // Always go to review URL if "yes"
        await handleToken(clientId);
        window.location.href = reviewUrl;
      } else if (responseType === 'no') {
        let hasSaidNoThisMonth = false;
        if (storageAvailable) {
          hasSaidNoThisMonth = localStorage.getItem(noKey) === 'true';
        } else {
          hasSaidNoThisMonth = true;
        }

        if (!hasSaidNoThisMonth) {
          await updateDoc(nfcTagDocRef, { totalNo: increment(1) });
          await addDoc(collection(db, 'clients', clientId, 'nfcTags', tagId, 'responses'), {
            responseType: 'no',
            responseDetail,
            timestamp: serverTimestamp(),
            clientId,
            assignedTo: assignedToField,
            placement: placementField,
            displayName: displayNameField,
          });

          if (storageAvailable) {
            localStorage.setItem(noKey, 'true');
          }

          await addDoc(collection(db, 'tagUsage'), {
            clientId,
            nfcTagId: tagId,
            action: 'no',
            timestamp: serverTimestamp(),
          });
        }

        // If it's Sonic => go to review URL; otherwise => go to the apology page
        await handleToken(clientId);
        if (isSonic) {
          window.location.href = reviewUrl;
        } else {
          navigate('/apology', {
            state: { apologyMessage: clientData.apologyMessage, clientId, tagId },
          });
        }
      }
    } catch (error) {
      console.error('Error handling response:', error);
      setErrorMsgResponse('An error occurred while processing your response.');
    }
  };

  // Updated handler for the assign card modal.
  // When the modal is submitted, we will:
  //   1. Retrieve the document from "parentCodeMap" with id equal to the submitted parentCode.
  //   2. Retrieve its "childMap" field.
  //   3. Query the "clients" collection for a document whose "childMap" field equals that value and whose "clientMatch" equals the submitted businessCode.
  //   4. If found, log the client's displayName and create the card entry.
  const handleAssignModalSubmit = async (codes) => {
    console.log("Assign modal submitted with codes:", codes);
    const { parentCode, businessCode, assignedTo, placement } = codes;
    try {
      // Get the parent document from parentCodeMap
      const parentDocRef = doc(db, 'parentCodeMap', parentCode);
      const parentDocSnap = await getDoc(parentDocRef);
      if (!parentDocSnap.exists()) {
        console.error("Parent code not found in parentCodeMap");
        return;
      }
      const parentData = parentDocSnap.data();
      console.log("Parent document data:", parentData);
      
      const { childMap: childMapValue } = parentData;
      console.log("childMapValue from parent:", childMapValue);
      console.log("Submitted businessCode (number):", Number(businessCode));

      // Query the clients collection for a matching client
      const clientQuery = query(
        collection(db, 'clients'),
        where('childMap', '==', childMapValue),
        where('clientMatch', '==', Number(businessCode))
      );
      const clientQuerySnapshot = await getDocs(clientQuery);
      if (clientQuerySnapshot.empty) {
        console.error(
          "No matching client found for childMap:",
          childMapValue,
          "and clientMatch:",
          Number(businessCode)
        );
        return;
      }
      // Assume the first matching document is the desired client.
      const clientDoc = clientQuerySnapshot.docs[0];
      const clientDataFound = clientDoc.data();
      console.log("Client displayName:", clientDataFound.displayName);

      // Create the card entry in the client's nfcTags collection.
      // The document ID is the tagId (which comes from the URL query parameter).
      const cardData = {
        assignedAt: serverTimestamp(),
        assignedTo: assignedTo,
        clientId: clientDoc.id,
        id: tagId,          // The tag id (document id)
        placement: placement,
        tagId: tagId,
      };
      await setDoc(doc(db, 'clients', clientDoc.id, 'nfcTags', tagId), cardData);
      console.log("Card entry created for tag:", tagId);
      // Wait 1 second after success before closing the modal and reloading the page.
      setTimeout(() => {
        setShowAssignCardModal(false);
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error in assignment submission:", error);
      // Optionally, do not close the modal here so that the user can try again.
    }
  };

  // Handler to simply close the modal if the user cancels.
  const handleAssignModalClose = () => {
    setShowAssignCardModal(false);
  };

  if (loading) {
    return (
      <Container
        sx={{
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(135deg, #1E88E5 30%, #D81B60 100%)',
          padding: '20px',
        }}
      >
        <CircularProgress style={{ color: '#fff' }} />
      </Container>
    );
  }

  if (showNoTagHomePage) {
    return <NoTagHomePage />;
  }

  if (errorMsg) {
    return (
      <Container
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          padding: '20px',
        }}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Container>
    );
  }

  if (errorMsgResponse) {
    return (
      <Container
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          padding: '20px',
        }}
      >
        <Alert severity="error">{errorMsgResponse}</Alert>
      </Container>
    );
  }

  // If the card is not assigned (or client data is not found), show the assign card modal.
  if (showAssignCardModal) {
    return (
      <AssignCardModal
        open={showAssignCardModal}
        handleClose={handleAssignModalClose}
        handleSubmit={handleAssignModalSubmit}
      />
    );
  }

  // Determine if this client is Sonic
  const isSonic = clientData && clientData.reviewPlatform === 'Sonic';

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1E88E5 30%, #D81B60 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: { xs: '20px', sm: '40px' },
        }}
      >
        <Container
          sx={{
            textAlign: 'center',
            maxWidth: { xs: '100%', sm: '600px' },
            padding: { xs: '20px', sm: '40px' },
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: '600',
                color: '#333',
                lineHeight: 1.4,
                fontSize: { xs: '1.2rem', sm: '1.5rem' },
                paddingX: { xs: '10px', sm: '20px' },
              }}
            >
              {isSonic
                ? 'How satisfied were you with your visit at Sonic overall?'
                : `Would you be willing to leave us a ${clientData?.starRating} ${clientData?.reviewPlatform} review today?`}
            </Typography>
          </motion.div>

          {isSonic ? (
            // Sonic-specific satisfaction buttons
            <Box
              sx={{
                marginTop: '30px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'center',
                paddingX: '10px',
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('no', 'Very Dissatisfied')}
                  sx={{
                    padding: isSmallScreen ? '8px 10px' : '12px 20px',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '0.9rem' : '1rem',
                    textTransform: 'none',
                    fontWeight: '600',
                    borderWidth: '2px',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.2,
                    '&:hover': {
                      backgroundColor: '#D81B60',
                      color: '#fff',
                      borderColor: '#D81B60',
                    },
                  }}
                >
                  😡 Very Dissatisfied 😡
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('no', 'Dissatisfied')}
                  sx={{
                    padding: isSmallScreen ? '8px 10px' : '12px 20px',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '0.9rem' : '1rem',
                    textTransform: 'none',
                    fontWeight: '600',
                    borderWidth: '2px',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.2,
                    '&:hover': {
                      backgroundColor: '#D81B60',
                      color: '#fff',
                      borderColor: '#D81B60',
                    },
                  }}
                >
                  ☹️ Dissatisfied ☹️
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('no', 'Neither Satisfied nor Dissatisfied')}
                  sx={{
                    padding: isSmallScreen ? '8px 10px' : '12px 20px',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '0.9rem' : '1rem',
                    textTransform: 'none',
                    fontWeight: '600',
                    borderWidth: '2px',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.2,
                    '&:hover': {
                      backgroundColor: '#D81B60',
                      color: '#fff',
                      borderColor: '#D81B60',
                    },
                  }}
                >
                  😐 Neither Satisfied nor Dissatisfied 😐
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('no', 'Satisfied')}
                  sx={{
                    padding: isSmallScreen ? '8px 10px' : '12px 20px',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '0.9rem' : '1rem',
                    textTransform: 'none',
                    fontWeight: '600',
                    borderWidth: '2px',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.2,
                    '&:hover': {
                      backgroundColor: '#D81B60',
                      color: '#fff',
                      borderColor: '#D81B60',
                    },
                  }}
                >
                  🙂 Satisfied 🙂
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('yes', 'Very Satisfied')}
                  sx={{
                    padding: isSmallScreen ? '9px 11px' : '13px 21px',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '0.95rem' : '1.05rem',
                    textTransform: 'none',
                    fontWeight: '600',
                    boxShadow: 'none',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.2,
                  }}
                >
                  😃 Very Satisfied 😃
                </Button>
              </motion.div>
            </Box>
          ) : (
            // Non-Sonic yes/no
            <Box
              sx={{
                marginTop: '40px',
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                style={{ width: '100%', maxWidth: '250px' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('yes', 'Yes')}
                  sx={{
                    padding: '12px 0',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '1rem' : '1rem',
                    textTransform: 'none',
                    width: '100%',
                    fontWeight: '600',
                    boxShadow: 'none',
                  }}
                >
                  Yes
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                style={{ width: '100%', maxWidth: '250px' }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size={isSmallScreen ? 'medium' : 'large'}
                  onClick={() => handleResponse('no', 'No')}
                  sx={{
                    padding: '12px 0',
                    borderRadius: '50px',
                    fontSize: isSmallScreen ? '1rem' : '1rem',
                    textTransform: 'none',
                    width: '100%',
                    fontWeight: '600',
                    borderWidth: '2px',
                    '&:hover': {
                      backgroundColor: '#D81B60',
                      color: '#fff',
                      borderColor: '#D81B60',
                    },
                  }}
                >
                  No
                </Button>
              </motion.div>
            </Box>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
